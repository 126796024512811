<template>
    <div style="min-width:1280px">
        <el-header class="header d-flex-center bg-white position-sticky-top box-shadow font-bold color-333 font-14">
            <div index="1" class="item p-16 d-inline-flex-center" @click="$nav.push('/')"><i class="icon icon-home icon-18 mr-5" />{{ $t('navbar.home') }}</div>
            <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/subscribe')"><i class="icon icon-stars icon-18 mr-5" />{{ $t('navbar.subscribe') }}</div>
            <div index="2" class="item p-16 d-inline-flex-center" @click="$nav.push('/market/BTC')"><i class="icon icon-change icon-18 mr-5" />{{ $t('navbar.trade') }}</div>
            <div index="3" class="item p-16  d-inline-flex-center mr-auto" @click="$nav.push('/help')"><i class="icon icon-help icon-18 mr-5" />{{ $t('navbar.help') }}</div>
            <el-dropdown @command="onLangSelect">
                <span class="item p-16 d-inline-flex-center">
                    <i class="icon icon-language icon-18 mr-5" />
                    <span class="cont-bold color-333 font-14">{{ langAlias[$i18n.locale] || $t('navbar.language') }}</span>
                    <i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu class="dropdown" slot="dropdown">
                    <el-dropdown-item v-for="item in languageList" :key="item.value" :command="item.value">{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-if="$store.state.userToken" @command="onAccountAction">
                <span class="item p-16 d-inline-flex-center" @click="onAccountAction('onAssets')">
                    <i class="icon icon-account icon-18 mr-5" />
                    <span class="cont-bold color-333 font-14">{{ $t('navbar.account') }}</span>
                    <i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu class="dropdown" slot="dropdown">
                    <el-dropdown-item command="onProfile">
                        <i class="el-icon-user"></i>
                        <span>{{ $t('navbar.user_center') }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="onAssets">
                        <i class="el-icon-coin"></i>
                        <span>{{ $t('navbar.wallet') }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="onLogout">
                        <i class="el-icon-switch-button"></i>
                        <span>{{ $t('navbar.logout') }}</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div v-else index="5" class="item p-16 d-inline-flex-center" @click="$nav.push('/user/login')">
                <i class="icon icon-account icon-18 mr-5" />{{ $t('navbar.sign_in') }}
            </div>
        </el-header>
        <el-main class="main pt-0 px-0 pb-30">
            <router-view />
        </el-main>
        <el-footer class="footer bg-white pt-50">
            <nav class="nav">
                <div class="item" @click="$nav.push('/help/6')">{{ $t('footer.about_us') }}</div>
                <span>/</span>
                <div class="item" @click="$nav.push('/help')">{{ $t('footer.help_center') }}</div>
                <span>/</span>
                <div class="item" @click="$nav.push('/fees')">{{ $t('footer.fees') }}</div>
                <span>/</span>
                <div class="item" @click="$nav.push('/help/4')">{{ $t('footer.user_agreement') }}</div>
            </nav>
            <p>{{ $t('home.title1') }} {{ $t('footer.copyright') }} © 2019-2024</p>
        </el-footer>
    </div>
</template>

<script>
import NotifyAudioFile from '@/assets/notify/1.mp3'
export default {
    data() {
        return {
            languageList: [
                {
                    name: 'English',
                    value: 'en'
                },
                {
                    name: 'Deutsch',
                    value: 'de'
                },
                {
                    name: 'Français',
                    value: 'fr'
                },
                {
                    name: '繁体中文',
                    value: 'zh-Hant'
                }
            ],
            langAlias: {
                'en': 'English',
                'fr': 'Français',
                'de': 'Deutsch',
                'it': 'Italiano',
                'nl': 'Nederlands',
                'zh-Hans': '简体中文',
                'zh-Hant': '繁体中文'
            },
            activeIndex: '1',
            loading: false,
            timer: null,
            notifyNews: null,
            notifyFund: null,
            notifyAudio: null
        }
    },
    created: function() {
        this.notifyAudio = new Audio(NotifyAudioFile)
        this.notifyAudio.classList.add('d-none')
        this.notifyAudio.preload = 'auto'
        this.fatchNotify();
        this.timer = window.setInterval(() => {
            if (document.hidden) return
            this.fatchNotify()
        }, 5000)
    },
    beforeDestroy: function() {
        window.clearInterval(this.timer)
    },    
    methods: {
        onLangSelect: function (locale) {
            this.$store.commit('locale', locale)
            this.$i18n.locale = locale
            // window.location.reload()
        },
        onAccountAction: function (action){
            if(!action) return
            this[action]()
        },
        onAssets: function(){
            this.$nav.push('/wallet')
        },
        onProfile: function(){
            this.$nav.push('/user/account')
        },
        onLogout: function () {
            this.$confirm('Are you sure you want to logout of this account ?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(async() => {
                this.$request.get('logout')
                this.$store.commit('userToken', '')
                this.$store.commit('userId', '')
                if(this.$route.path !== '/'){
                    this.$nav.replace('/')
                }
            })
            
            
        },
        fatchNotify: async function() {
            if (this.loading) return
            this.loading = true
            try {
                const { data } = await this.$requestNotState.post('notify', { news_id: this.$store.state.newsID })
                this.loading = false
                if (data.news.show) {
                    if (this.notifyNews !== null) return
                    this.$store.commit('newsID', data.news.id)
                    this.notifyNews = this.$notify.info({
                        title: data.news.title,
                        message: '<h3 style="text-align: left;cursor: pointer;">' + data.news.message + '</h3><p>' + data.news.create_time + '</p>',
                        duration: 0,
                        dangerouslyUseHTMLString: true,
                        position: 'bottom-right',
                        onClick: () => {
                            this.$router.push('/notice/details/'+data.news.id)
                            this.notifyNews.close()
                        },
                        onClose: () => {
                            this.notifyNews = null
                        }
                    })
                }
                if (data.fund.show) {
                    if(data.audio) this.notifyAudio.play()
                    if (this.notifyFund !== null) return
                    this.notifyFund = this.$notify.info({
                        title: this.$t('navbar.fund'),
                        message: this.$t('fund.notify_message'),
                        duration: 0,
                        onClick: () => {
                            this.$router.push('/fund/log')
                            this.notifyFund.close()
                        },
                        onClose: () => {
                            this.notifyFund = null
                        }
                    })
                }
            } catch (error) {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header{
    .item{
        cursor: pointer;
    }
}
.main{
    background-color: $bgColor;
}
.footer{
    height: auto !important;
    padding: 50px 30px;
    box-shadow: 0 -2px 2px 0 rgb(155 155 155 / 10%);
    nav{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        span{
            margin: 0 8px;
        }
        div{
            cursor: pointer;
        }
    }
    p{
        margin: 0;
        text-align: center;
        font-size: 14px;
    }
}
</style>